import type { AxiosInstance } from 'axios';
import { BACKEND_DASHBOARD, axiosInstance } from '../configs';
import type User from '../interfaces/system/user';
import type Profile from '../interfaces/dashboard/profile';
import type Dashboard from '../interfaces/dashboard';

class QueryDashboard {
  private backend: AxiosInstance;

  private url: string;

  constructor() {
    this.backend = axiosInstance(BACKEND_DASHBOARD);
    this.url = '/';
  }

  public changePassword = async (_id: User['_id'], data: User): Promise<Profile> => {
    const result = await this.backend.put<Profile>(`${this.url}profile/${_id}`, data);
    return result.data;
  };

  public index = async (): Promise<Dashboard> => {
    const result = await this.backend.get<Dashboard>(this.url);
    return result.data;
  };

  public profile = async (): Promise<Profile> => {
    const result = await this.backend.get<Profile>(`${this.url}profile`);
    return result.data;
  };
}

export default QueryDashboard;
