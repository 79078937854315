import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import type { InputSelectProps } from './props';

function InputSelect<T>(props: InputSelectProps<T>): JSX.Element {
  const {
    className,
    controlId,
    data,
    dataKey,
    disabled,
    errMsg,
    inputRef,
    isInvalid,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
  } = props;

  return (
    <Form.Group className={className} controlId={controlId}>
      <FloatingLabel controlId={controlId} label={label}>
        <Form.Select
          ref={inputRef}
          disabled={disabled}
          isInvalid={isInvalid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
        >
          {data.map((item: T & { _id: string }) => (
            <option key={item._id} value={item._id}>{item[dataKey].toString()}</option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
}

InputSelect.defaultProps = {
  inputRef: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
};

export default InputSelect;
