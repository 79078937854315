import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import type { FormChangePasswordProps } from './props';
import type ChangePassword from '../../interfaces/dashboard/changePassword';
import { SaveButton } from '../button';
import { InputEmail, InputPassword } from '../input';

function FormChangePassword(props: FormChangePasswordProps): JSX.Element {
  const {
    isLoading, data, error, reset, validation, mutation,
  } = props;

  const { kolom, formulir } = validation;

  useEffect(() => {
    formulir.clearErrors();
  }, []);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((item: keyof ChangePassword) => {
        if (item !== 'password') {
          formulir.setValue(item, data[item]);
        }
      });
    }
  }, [data, formulir]);

  useEffect(() => {
    if (reset) {
      formulir.reset();
    }
  }, [reset, formulir]);

  useEffect(() => {
    if (error && error.response) {
      if (error.response.data) {
        Object.keys(error.response.data).forEach((item: keyof ChangePassword) => {
          formulir.setError(item, error.response.data[item]);
          formulir.setFocus(item);
        });
      }
    }
  }, [error, formulir]);

  return (
    <Form>
      <fieldset className="d-grid gap-3" disabled={isLoading}>
        <InputEmail
          autoFocus
          controlId="UserEmail"
          disabled
          errMsg={formulir.formState.errors.email ? formulir.formState.errors.email.message : null}
          inputRef={kolom.email.ref}
          isInvalid={!!formulir.formState.errors.email}
          label={(
            <>
              Email
              <i className="text-danger"> *</i>
            </>
          )}
          name={kolom.email.name}
          onBlur={kolom.email.onBlur}
          onChange={kolom.email.onChange}
          placeholder="Email *"
        />
        <InputPassword
          controlId="UserPassword"
          errMsg={formulir.formState.errors.password
            ? formulir.formState.errors.password.message : null}
          inputRef={kolom.password.ref}
          isInvalid={!!formulir.formState.errors.password}
          label={(
            <>
              Password
              <i className="text-danger"> *</i>
            </>
          )}
          name={kolom.password.name}
          onBlur={kolom.password.onBlur}
          onChange={kolom.password.onChange}
          placeholder="Password *"
        />
        <InputPassword
          controlId="UlangiPassword"
          errMsg={formulir.formState.errors.ulangiPassword
            ? formulir.formState.errors.ulangiPassword.message : null}
          inputRef={kolom.ulangiPassword.ref}
          isInvalid={!!formulir.formState.errors.ulangiPassword}
          label={(
            <>
              Ulangi Password
              <i className="text-danger"> *</i>
            </>
          )}
          name={kolom.ulangiPassword.name}
          onBlur={kolom.ulangiPassword.onBlur}
          onChange={kolom.ulangiPassword.onChange}
          placeholder="Ulangi Password *"
        />
        <div className="d-grid">
          <SaveButton
            isLoading={isLoading}
            onClick={formulir.handleSubmit((formData) => { mutation.mutate(formData); })}
          >
            Simpan
          </SaveButton>
        </div>
      </fieldset>
    </Form>
  );
}

FormChangePassword.defaultProps = {
  data: undefined,
};

export default FormChangePassword;
