import type { AxiosInstance } from 'axios';
import type {
  DPK, FPK, JK, PK, SA, TPJK, TPK,
} from '../_type';
import QueryJadwalKerjaBase from './_base';

class QueryPolaKerja extends QueryJadwalKerjaBase<JK['_id'], PK, TPK, FPK> {
  constructor(url: string, backend: AxiosInstance) {
    super(`${url}/pola-kerja`, backend);
  }

  public delete = async (_idJK: JK['_id'], _id: PK['_id']): Promise<SA> => {
    const result = await this.backend.delete<SA>(`${this.url}/${_idJK}/${_id}`);
    return result.data;
  };

  public getById = async (_idJK: JK['_id'], _id: PK['_id']): Promise<PK> => {
    const result = await this.backend.get<PK>(`${this.url}/${_idJK}/${_id}`);
    return result.data;
  };

  public getByIdDetail = async (_idJK: JK['_id'], _id: PK['_id']): Promise<DPK> => {
    const result = await this.backend.get<DPK>(`${this.url}/${_idJK}/${_id}/detail`);
    return result.data;
  };

  public tablePreview = async (_idJK: JK['_id'], bulan: Date): Promise<TPJK[]> => {
    const result = await this.backend.get<TPJK[]>(`${this.url}/${_idJK}/table-preview`, {
      params: {
        bulan,
      },
    });
    return result.data;
  };

  public update = async (_idJK: JK['_id'], data: PK): Promise<SA> => {
    const result = await this.backend.put<SA>(`${this.url}/${_idJK}/${data._id}`, data);
    return result.data;
  };
}

export default QueryPolaKerja;
