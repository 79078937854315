import React from 'react';
import { Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { CardWithButton } from '../components/card';
import { ChangePassword } from '../components/button';
import { BasicDetail } from '../components/detailData';
import type Profile from '../interfaces/dashboard/profile';
import { columnsDetail } from '../configs/profile';
import Query from '../queries';

function PageProfile(): JSX.Element {
  const { isLoading, data } = useQuery<Profile>(
    ['dashboard/profile/detail'],
    async () => new Query().dashboard.profile(),
  );

  return (
    <Row className="p-1 g-2">
      <CardWithButton
        button={<ChangePassword href="/profile/change-password">Ganti Password</ChangePassword>}
        title="Profile"
      >
        <BasicDetail<Profile> columns={columnsDetail} isLoading={isLoading} rows={data} />
      </CardWithButton>
    </Row>
  );
}

export default PageProfile;
