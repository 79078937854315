import React from 'react';
import type { RouteObject } from 'react-router-dom';
import PageChangePassword from '../pages/changePassword';
import PageProfile from '../pages/profile';

function RouteProfile(): RouteObject[] {
  return [
    {
      index: true,
      element: <PageProfile />,
    },
    {
      path: 'change-password',
      element: <PageChangePassword />,
    },
  ];
}

export default RouteProfile;
