import React, { useContext, useEffect, useState } from 'react';
import { useRoutes } from 'react-router';
import type { AxiosError } from 'axios';
import { GlobalDispatchContext } from './states/context';
import QueryDashboard from './queries/dashboard';
import TemplateLoading from './templates/loading';
import RouteDashboard from './routes/dashboard';
import type User from './interfaces/system/user';
import { LargeLoading } from './components/loading';
import { FRONTEND_OTENTIKASI } from './configs';

function App(): JSX.Element {
  const [success, setSuccess] = useState<User>();
  const [error, setError] = useState<AxiosError>();
  const dispatch = useContext(GlobalDispatchContext);
  const Element = useRoutes(RouteDashboard());

  useEffect(() => {
    new QueryDashboard().index()
      .then(({
        user, karyawan, jabatan, hakAkses,
      }) => {
        setSuccess(user);
        dispatch({ type: 'SET_USER', payload: user });
        dispatch({ type: 'SET_KARYAWAN', payload: karyawan });
        dispatch({ type: 'SET_JABATAN', payload: jabatan });
        dispatch({ type: 'SET_HAK_AKSES', payload: hakAkses });
      })
      .catch((err: AxiosError) => {
        if (err.response.status === 401) {
          window.location.href = FRONTEND_OTENTIKASI;
        }
        setError(err);
      });
  }, [dispatch]);

  if (!error && !success) {
    return (
      <TemplateLoading>
        <LargeLoading />
      </TemplateLoading>
    );
  }

  if (error && error.response.status === 401) {
    window.location.href = FRONTEND_OTENTIKASI;
  }

  if (success) {
    return Element;
  }
}

export default App;
