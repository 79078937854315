import type { AxiosInstance } from 'axios';
import QueryPolaKerja from './polaKerja';
import type {
  DJK, FJK, JK, TJK, TPJK,
} from '../_type';
import QueryPolaLembur from './polaLembur';
import QueryJasikaBase from '../_base';

class QueryJadwalKerja extends QueryJasikaBase<JK, DJK, JK['_id'], TJK, FJK> {
  public polaKerja: QueryPolaKerja;

  public polaLembur: QueryPolaLembur;

  constructor(backend: AxiosInstance) {
    super('/jadwal-kerja', backend);

    this.polaKerja = new QueryPolaKerja(this.url, this.backend);
    this.polaLembur = new QueryPolaLembur(this.url, this.backend);
  }

  public tablePreview = async (bulan: Date): Promise<TPJK[]> => {
    const result = await this.backend.get<TPJK[]>(`${this.url}/table-preview`, {
      params: {
        bulan,
      },
    });
    return result.data;
  };
}

export default QueryJadwalKerja;
