import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import type { InputEmailProps } from './props';

function InputEmail(props: InputEmailProps): JSX.Element {
  const {
    autoFocus,
    className,
    controlId,
    disabled,
    errMsg,
    inputRef,
    isInvalid,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
  } = props;

  return (
    <Form.Group className={className} controlId={controlId}>
      <FloatingLabel controlId={controlId} label={label}>
        <Form.Control
          ref={inputRef}
          autoFocus={autoFocus}
          disabled={disabled}
          isInvalid={isInvalid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          type="email"
        />
        <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
}

InputEmail.defaultProps = {
  autoFocus: undefined,
  disabled: undefined,
  inputRef: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
};

export default InputEmail;
