import type { AxiosInstance } from 'axios';
import QueryJasikaBase from './_base';
import type {
  DSC, FSC, SA, SC, TSC,
} from './_type';

class QuerySaldoCuti extends QueryJasikaBase<SC, DSC, SC['_id'], TSC, FSC> {
  constructor(backend: AxiosInstance) {
    super('/saldo-cuti', backend);
  }

  public add = async (data: SC): Promise<SA> => {
    const result = await this.backend.post<SA>(`${this.url}/add`, data);
    return result.data;
  };

  public getDetailSelf = async (): Promise<DSC> => {
    const result = await this.backend.get<DSC>(`${this.url}/detail/self`);
    return result.data;
  };
}

export default QuerySaldoCuti;
