import type { AxiosInstance } from 'axios';
import QueryBase from '../_base';
import type {
  DU, FU, TU, U,
} from './_type';

class QueryUser extends QueryBase<U, DU, U['_id'], TU> {
  constructor(backend: AxiosInstance) {
    super('/user', backend);
  }

  public formData = async (): Promise<FU> => {
    const result = await this.backend.get<FU>(`${this.url}/formData`);
    return result.data;
  };
}

export default QueryUser;
