import React from 'react';
import { Card } from 'react-bootstrap';
import type Props from './props';

function BasicCard(props: Props): JSX.Element {
  const {
    children,
    title,
    titleCenter,
  } = props;

  const className = ['lh-base'];

  if (titleCenter) {
    className.push('text-center');
  }

  const titleClassName = className.length === 1 ? className[0] : className.join(' ');

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <Card.Title className={titleClassName}>{title}</Card.Title>
        <hr />
        {children}
      </Card.Body>
    </Card>
  );
}

BasicCard.defaultProps = {
  children: undefined,
};

export default BasicCard;
