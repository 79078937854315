import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import type { CardMenuProps } from './props';

function CardMenu(props: CardMenuProps): JSX.Element {
  const { href, icon: Icon, title } = props;

  const OnClick = (): void => {
    window.location.href = href;
  };

  return (
    <Card bg="primary" border="primary" className="overflow-hidden bg-gradient m-1" style={{ width: '14rem' }} text="dark">
      <Icon className="position-absolute top-50 start-50 translate-middle-y opacity-25" color="white" size={180} />
      <Card.Body className="position-relative">
        <Card.Title className="text-center text-white lh-base">{title}</Card.Title>
        <hr className="bg-white" style={{ height: '4px', color: '#FFFFFF' }} />
        <Stack>
          <Button className="bg-gradient shadow-sm text-primary fw-bold" onClick={OnClick} variant="light">Buka</Button>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default CardMenu;
