import type { SweetAlertOptions } from 'sweetalert2';
import type { AxiosInstance } from 'axios';

class QueryBase<Dat, DatDet, Id, Tab> {
  public backend: AxiosInstance;

  public url: string;

  constructor(url: string, backend: AxiosInstance) {
    this.url = url;
    this.backend = backend;
  }

  public create = async (data: Dat): Promise<SweetAlertOptions> => {
    const result = await this.backend.post<SweetAlertOptions>(this.url, data);
    return result.data;
  };

  public delete = async (_id: Id): Promise<SweetAlertOptions> => {
    const result = await this.backend.delete<SweetAlertOptions>(`${this.url}/${_id}`);
    return result.data;
  };

  public getById = async (_id: Id): Promise<Dat> => {
    const result = await this.backend.get<Dat>(`${this.url}/${_id}`);
    return result.data;
  };

  public getByIdDetail = async (_id: Id): Promise<DatDet> => {
    const result = await this.backend.get<DatDet>(`${this.url}/${_id}/detail`);
    return result.data;
  };

  public table = async (): Promise<Tab[]> => {
    const result = await this.backend.get<Tab[]>(`${this.url}/table`);
    return result.data;
  };

  public update = async (data: Dat & { _id: string }): Promise<SweetAlertOptions> => {
    const result = await this.backend.put<SweetAlertOptions>(`${this.url}/${data._id}`, data);
    return result.data;
  };
}

export default QueryBase;
