import QueryAuthentication from './authentication';
import QueryDashboard from './dashboard';
import QueryJasika from './jasika';
import QuerySystem from './system';

class Query {
  public authentication: QueryAuthentication;

  public dashboard: QueryDashboard;

  public jasika: QueryJasika;

  public system: QuerySystem;

  constructor() {
    this.authentication = new QueryAuthentication();
    this.dashboard = new QueryDashboard();
    this.jasika = new QueryJasika();
    this.system = new QuerySystem();
  }
}

export default Query;
