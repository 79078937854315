import QueryBase from '../_base';

class QueryJasikaBase<Dat, DatDet, Id, Tab, Form> extends QueryBase<Dat, DatDet, Id, Tab> {
  public formData = async (): Promise<Form> => {
    const result = await this.backend.get<Form>(`${this.url}/formData`);
    return result.data;
  };
}

export default QueryJasikaBase;
