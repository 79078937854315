import React from 'react';
import { Card } from 'react-bootstrap';
import type { CardWithButtonProps } from './props';

function CardWithButton(props: CardWithButtonProps): JSX.Element {
  const { button, children, title } = props;

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <Card.Title className="d-flex justify-content-between lh-base">
          {title}
          {button}
        </Card.Title>
        <hr />
        {children}
      </Card.Body>
    </Card>
  );
}

CardWithButton.defaultProps = {
  children: undefined,
};

export default CardWithButton;
