import type { AxiosInstance } from 'axios';
import { BACKEND_OTENTIKASI, axiosInstance } from '../configs';
import type User from '../interfaces/system/user';

class QueryAuthentication {
  private backend: AxiosInstance;

  private url: string;

  constructor() {
    this.backend = axiosInstance(BACKEND_OTENTIKASI);
    this.url = '/';
  }

  public index = async (): Promise<User> => {
    const result = await this.backend.get<User>(this.url);
    return result.data;
  };

  public login = async (data: User): Promise<User> => {
    const result = await this.backend.post<User>(`${this.url}login`, data);
    return result.data;
  };

  public logout = async (): Promise<User> => {
    const result = await this.backend.delete<User>(`${this.url}logout`);
    return result.data;
  };
}

export default QueryAuthentication;
