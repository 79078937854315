import React from 'react';
import { Col, Row } from 'react-bootstrap';
import type Props from './props';

function TemplateFormulir(props: Props): JSX.Element {
  const {
    children,
  } = props;

  return (
    <Row className="p-1 g-2">
      <Col lg={{ span: 7 }}>
        {children}
      </Col>
    </Row>
  );
}

export default TemplateFormulir;
