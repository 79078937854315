import React from 'react';
import {
  Col,
  Container, Row,
} from 'react-bootstrap';
import { Outlet } from 'react-router';
import NavigationBar from '../components/navbar';

function TemplateDashboard(): JSX.Element {
  return (
    <>
      <NavigationBar />
      <Container className="py-1">
        <Row className="p-1 g-2">
          <Col>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TemplateDashboard;
