import type { UseFormRegisterReturn, UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { REGEX_EMAIL } from '../configs';
import type ChangePassword from '../interfaces/dashboard/changePassword';

interface ValidateChangePassword {
  formulir: UseFormReturn<ChangePassword>;
  kolom: {
    email: UseFormRegisterReturn<'email'>;
    password: UseFormRegisterReturn<'password'>;
    ulangiPassword: UseFormRegisterReturn<'ulangiPassword'>;
  };
}

const useValidateChangePassword = (): ValidateChangePassword => {
  const formulir = useForm<ChangePassword>();

  const email = formulir.register('email', {
    required: 'Email is required',
    pattern: {
      value: REGEX_EMAIL,
      message: 'Invalid email address',
    },
  });
  const password = formulir.register('password', {
    required: 'Password is required',
  });
  const ulangiPassword = formulir.register('ulangiPassword', {
    required: 'Password is required',
    validate: (pswd: string) => {
      if (pswd !== formulir.watch('password')) {
        return 'Password tidak sama';
      }
      return null;
    },
  });

  return {
    formulir,
    kolom: {
      email,
      password,
      ulangiPassword,
    },
  };
};

export default useValidateChangePassword;
