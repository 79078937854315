import type { ColumnsDetail } from '../interfaces';
import type Profile from '../interfaces/dashboard/profile';

export const columnsDetail: ColumnsDetail<Profile>[] = [{
  name: 'Email',
  key: 'email',
},
{
  name: 'Nama Karyawan',
  key: 'nama',
},
{
  name: 'Tempat Tanggal Lahir',
  key: 'tempatTanggalLahir',
},
{
  name: 'Alamat',
  key: 'alamat',
},
{
  name: 'Jenis Kelamin',
  key: 'jenisKelamin',
},
{
  name: 'Agama',
  key: 'agama',
},
{
  name: 'Jabatan',
  key: 'jabatan',
},
];
