import type { StateAction } from '../interfaces';

const reducer = (state: object, action: StateAction): object => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_USER': {
      return {
        ...state,
        user: payload,
      };
    }

    case 'SET_KARYAWAN': {
      return {
        ...state,
        karyawan: payload,
      };
    }

    case 'SET_JABATAN': {
      return {
        ...state,
        jabatan: payload,
      };
    }

    case 'SET_HAK_AKSES': {
      return {
        ...state,
        hakAkses: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
