import React from 'react';
import { Spinner } from 'react-bootstrap';

function LargeLoading(): JSX.Element {
  return (
    <Spinner animation="border" className="d-block m-auto" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default LargeLoading;
