import React from 'react';
import { Form } from 'react-bootstrap';
import type { InputSwitchProps } from './props';

function InputSwitch(props: InputSwitchProps): JSX.Element {
  const {
    className,
    controlId,
    disabled,
    inputRef,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
    value,
  } = props;

  return (
    <Form.Group className={className} controlId={controlId}>
      <Form.Check
        ref={inputRef}
        disabled={disabled}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type="switch"
        value={value}
      />
    </Form.Group>
  );
}

InputSwitch.defaultProps = {
  inputRef: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  value: undefined,
};

export default InputSwitch;
