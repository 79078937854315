import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import Provider from './provider';

document.body.classList.add('bg-secondary');
document.body.classList.add('bg-gradient');
document.body.classList.add('bg-opacity-25');
document.body.classList.add('min-vh-100');

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <Provider>
    <App />
  </Provider>,
);
