import type { AxiosInstance } from 'axios';
import type { SA } from '../_type';

class QueryJadwalKerjaBase<Id, Data, Table, Form> {
  public backend: AxiosInstance;

  public url: string;

  constructor(url: string, backend: AxiosInstance) {
    this.backend = backend;
    this.url = url;
  }

  public create = async (_idJK: Id, data: Data): Promise<SA> => {
    const result = await this.backend.post<SA>(`${this.url}/${_idJK}`, data);
    return result.data;
  };

  public formData = async (_idJK: Id): Promise<Form> => {
    const result = await this.backend.get<Form>(`${this.url}/formData/${_idJK}`);
    return result.data;
  };

  public table = async (_idJK: Id): Promise<Table[]> => {
    const result = await this.backend.get<Table[]>(`${this.url}/${_idJK}/table`);
    return result.data;
  };
}

export default QueryJadwalKerjaBase;
