import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useState } from 'react';
import QueryAuthentication from '../queries/authentication';
import type User from '../interfaces/system/user';
import type { ErrorResponse, HookLogoutReturn } from '../interfaces';
import { FRONTEND_OTENTIKASI } from '../configs';

const useLogout = (): HookLogoutReturn => {
  const [error, setError] = useState<AxiosError<ErrorResponse<User>>>();

  const onSuccess = (): void => {
    window.location.href = FRONTEND_OTENTIKASI;
  };

  const onError = (err: AxiosError<ErrorResponse<User>>): void => {
    if (err.response?.status === 401) {
      window.location.href = FRONTEND_OTENTIKASI;
    }
    setError(err);
  };

  const logoutMutation = useMutation(new QueryAuthentication().logout, {
    onSuccess,
    onError,
  });

  return {
    error,
    mutation: logoutMutation,
  };
};

export default useLogout;
