import React, { useContext } from 'react';
import TemplateFormulir from '../templates/formulir';
import { GlobalStateContext } from '../states/context';
import { BasicCard } from '../components/card';
import useChangePassword from '../hooks/profile/changePassword';
import { useValidateChangePassword } from '../validations';
import FormChangePassword from '../components/form/changePassword';

function PageChangePassword(): JSX.Element {
  const validation = useValidateChangePassword();
  const { mutation, error } = useChangePassword();
  const { user } = useContext(GlobalStateContext);

  return (
    <TemplateFormulir>
      <BasicCard title="Ganti Password">
        <FormChangePassword
          data={user}
          error={error}
          isLoading={mutation.isLoading}
          mutation={mutation}
          validation={validation}
        />
      </BasicCard>
    </TemplateFormulir>
  );
}

export default PageChangePassword;
