import type { AxiosInstance } from 'axios';
import { BACKEND_SISTEM, axiosInstance } from '../../configs';
import type System from '../../interfaces/system';
import QueryBase from '../_base';
import type { DHA, HA, THA } from './_type';
import QueryUser from './user';

class QuerySystem {
  public hakAkses: QueryBase<HA, DHA, HA['_id'], THA>;

  public user: QueryUser;

  private backend: AxiosInstance;

  private url: string;

  constructor() {
    this.backend = axiosInstance(BACKEND_SISTEM);
    this.url = '/';
    this.hakAkses = new QueryBase(`${this.url}hak-akses`, this.backend);
    this.user = new QueryUser(this.backend);
  }

  public index = async (): Promise<System> => {
    const result = await this.backend.get<System>(this.url);

    return result.data;
  };
}

export default QuerySystem;
