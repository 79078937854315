import type { AxiosInstance } from 'axios';
import QueryJasikaBase from './_base';
import type {
  DP, FP, JamK, P, SA, TP,
} from './_type';

class QueryPermohonan extends QueryJasikaBase<P, DP, P['_id'], TP, FP> {
  constructor(backend: AxiosInstance) {
    super('/permohonan', backend);
  }

  public approve = async (_id: P['_id']): Promise<SA> => {
    const result = await this.backend.put<SA>(`${this.url}/approve/${_id}`);
    return result.data;
  };

  public jamKerja = async (hari: Date): Promise<JamK> => {
    const result = await this.backend.get<JamK>(`${this.url}/jam-kerja`, {
      params: {
        hari,
      },
    });
    return result.data;
  };

  public reject = async (_id: P['_id']): Promise<SA> => {
    const result = await this.backend.put<SA>(`${this.url}/reject/${_id}`);
    return result.data;
  };
}

export default QueryPermohonan;
