import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { GlobalStateContext } from '../../states/context';
import type User from '../../interfaces/system/user';
import type { ErrorResponse, HookReturn } from '../../interfaces';
import Query from '../../queries';
import { FRONTEND_OTENTIKASI } from '../../configs';

const useChangePassword = (): HookReturn<User> => {
  const [error, setError] = useState<AxiosError<ErrorResponse<User>>>();
  const navigate = useNavigate();
  const { user } = useContext(GlobalStateContext);

  const onSuccess = (): void => {
    navigate('/');
  };
  const onError = (err: AxiosError<ErrorResponse<User>>): void => {
    if (err.response.status === 401) {
      window.location.href = FRONTEND_OTENTIKASI;
    }
    setError(err);
  };
  const mutation = useMutation(
    async (data: User) => new Query().dashboard.changePassword(user._id, data),
    { onSuccess, onError },
  );

  return {
    error,
    mutation,
  };
};

export default useChangePassword;
