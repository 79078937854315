import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LargeLoading from '../loading/large';
import type Props from './props';

function BasicDetail<T>(props: Props<T>): JSX.Element {
  const {
    columns,
    isLoading,
    rows,
  } = props;

  if (isLoading || !rows) { return (<LargeLoading />); }

  const renderListItems = (key: string): JSX.Element => {
    const arr = rows[key] as string[];
    return (
      <ul>
        {arr.map((item) => (<li key={item}>{item}</li>))}
      </ul>
    );
  };

  const renderColumns = (): JSX.Element[] => columns.map((column) => {
    const key = column.key.toString();
    const rowData = rows[key];

    if (Array.isArray(rowData) || rowData) {
      return (
        <Row key={column.name} className="mb-2">
          <Col md={3} xs={12}><strong>{column.name}</strong></Col>
          <Col md={9} xs={12}>
            :
            {' '}
            {Array.isArray(rowData) ? renderListItems(key) : rowData}
          </Col>
        </Row>
      );
    }

    return null;
  });

  return (<>{renderColumns()}</>);
}

BasicDetail.defaultProps = {
  isLoading: undefined,
  rows: undefined,
};

export default BasicDetail;
