import Axios from 'axios';
import type { AxiosInstance } from 'axios';

export const axiosInstance = (baseUrl: string | undefined): AxiosInstance => Axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

export const ATURAN = {
  jasika: {
    jabatan: {
      create: 'jasika:jabatan:create',
      delete: 'jasika:jabatan:delete',
      getById: 'jasika:jabatan:get-by-id',
      getByIdDetail: 'jasika:jabatan:get-by-id-detail',
      table: 'jasika:jabatan:table',
      update: 'jasika:jabatan:update',
    },
    jadwalKerja: {
      create: 'jasika:jadwal-kerja:create',
      delete: 'jasika:jadwal-kerja:delete',
      getById: 'jasika:jadwal-kerja:get-by-id',
      getByIdDetail: 'jasika:jadwal-kerja:get-by-id-detail',
      table: 'jasika:jadwal-kerja:table',
      update: 'jasika:jadwal-kerja:update',
    },
    jamKerja: {
      create: 'jasika:jam-kerja:create',
      delete: 'jasika:jam-kerja:delete',
      getById: 'jasika:jam-kerja:get-by-id',
      getByIdDetail: 'jasika:jam-kerja:get-by-id-detail',
      table: 'jasika:jam-kerja:table',
      update: 'jasika:jam-kerja:update',
    },
    karyawan: {
      create: 'jasika:karyawan:create',
      delete: 'jasika:karyawan:delete',
      getById: 'jasika:karyawan:get-by-id',
      getByIdDetail: 'jasika:karyawan:get-by-id-detail',
      table: 'jasika:karyawan:table',
      update: 'jasika:karyawan:update',
    },
    liburNasional: {
      create: 'jasika:libur-nasional:create',
      delete: 'jasika:libur-nasional:delete',
      getById: 'jasika:libur-nasional:get-by-id',
      getByIdDetail: 'jasika:libur-nasional:get-by-id-detail',
      table: 'jasika:libur-nasional:table',
      update: 'jasika:libur-nasional:update',
    },
    permohonan: {
      create: 'jasika:permohonan:create',
      delete: 'jasika:permohonan:delete',
      getById: 'jasika:permohonan:get-by-id',
      getByIdDetail: 'jasika:permohonan:get-by-id-detail',
      table: 'jasika:permohonan:table',
      update: 'jasika:permohonan:update',
    },
    polaKerja: {
      create: 'jasika:jadwal-kerja:pola-kerja:create',
      delete: 'jasika:jadwal-kerja:pola-kerja:delete',
      getById: 'jasika:jadwal-kerja:pola-kerja:get-by-id',
      getByIdDetail: 'jasika:jadwal-kerja:pola-kerja:get-by-id-detail',
      table: 'jasika:jadwal-kerja:pola-kerja:table',
      update: 'jasika:jadwal-kerja:pola-kerja:update',
    },
    polaLembur: {
      create: 'jasika:jadwal-kerja:pola-lembur:create',
      delete: 'jasika:jadwal-kerja:pola-lembur:delete',
      getById: 'jasika:jadwal-kerja:pola-lembur:get-by-id',
      getByIdDetail: 'jasika:jadwal-kerja:pola-lembur:get-by-id-detail',
      table: 'jasika:jadwal-kerja:pola-lembur:table',
      update: 'jasika:jadwal-kerja:pola-lembur:update',
    },
    revisiJadwal: {
      create: 'jasika:revisi-jadwal:create',
      delete: 'jasika:revisi-jadwal:delete',
      getById: 'jasika:revisi-jadwal:get-by-id',
      getByIdDetail: 'jasika:revisi-jadwal:get-by-id-detail',
      table: 'jasika:revisi-jadwal:table',
      update: 'jasika:revisi-jadwal:update',
    },
    saldoCuti: {
      create: 'jasika:saldo-cuti:create',
      delete: 'jasika:saldo-cuti:delete',
      getById: 'jasika:saldo-cuti:get-by-id',
      getByIdDetail: 'jasika:saldo-cuti:get-by-id-detail',
      table: 'jasika:saldo-cuti:table',
      update: 'jasika:saldo-cuti:update',
    },
  },
  system: {
    hakAkses: {
      create: 'system:hak-akses:create',
      delete: 'system:hak-akses:delete',
      getById: 'system:hak-akses:get-by-id',
      getByIdDetail: 'system:hak-akses:get-by-id-detail',
      table: 'system:hak-akses:table',
      update: 'system:hak-akses:update',
    },
    user: {
      create: 'system:user:create',
      delete: 'system:user:delete',
      getById: 'system:user:get-by-id',
      getByIdDetail: 'system:user:get-by-id-detail',
      table: 'system:user:table',
      update: 'system:user:update',
    },
  },
};

export const BACKEND_DASHBOARD = 'https://jasika.jalawave.net.id:3001';
export const BACKEND_OPERASIONAL = 'https://jasika.jalawave.net.id:3020';
export const BACKEND_OTENTIKASI = 'https://jasika.jalawave.net.id:3002';
export const BACKEND_SISTEM = 'https://jasika.jalawave.net.id:3003';
export const FRONTEND_DASHBOARD = 'https://jasika.jalawave.net.id';
export const FRONTEND_OPERASIONAL = 'https://jasika.jalawave.net.id:6020';
export const FRONTEND_OTENTIKASI = 'https://jasika.jalawave.net.id:6002';
export const FRONTEND_SISTEM = 'https://jasika.jalawave.net.id:6003';

export const REGEX_EMAIL = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
