import type { AxiosInstance } from 'axios';
import type {
  FPL, JK, PL, SA, TPL,
} from '../_type';
import QueryJadwalKerjaBase from './_base';

class QueryPolaLembur extends QueryJadwalKerjaBase<JK['_id'], PL, TPL, FPL> {
  constructor(url: string, backend: AxiosInstance) {
    super(`${url}/pola-lembur`, backend);
  }

  public delete = async (_idJK: JK['_id']): Promise<SA> => {
    const result = await this.backend.delete<SA>(`${this.url}/${_idJK}`);
    return result.data;
  };

  public update = async (_idJK: JK['_id'], data: PL): Promise<SA> => {
    const result = await this.backend.put<SA>(`${this.url}/${_idJK}`, data);
    return result.data;
  };
}

export default QueryPolaLembur;
