import React, { useContext } from 'react';
import {
  Nav, NavDropdown, Container, Navbar,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { GlobalStateContext } from '../states/context';
import useLogout from '../hooks/logout';

function NavigationBar(): JSX.Element {
  const { karyawan } = useContext(GlobalStateContext);

  const { mutation } = useLogout();

  return (
    <Navbar bg="primary" expand="lg" sticky="top" variant="dark">
      <Container fluid>
        <Navbar.Brand href="/">Dashboard</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-support" />
        <Navbar.Collapse className="justify-content-end" id="navbar-support">
          <Nav>
            <NavDropdown align="end" className="fw-bold" id="basic-nav-dropdown" title={karyawan.nama}>
              <LinkContainer to="/profile">
                <NavDropdown.Item>
                  Profil
                </NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={(): void => { mutation.mutate(); }}
              >
                Keluar
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
