import type { AxiosInstance } from 'axios';
import { BACKEND_OPERASIONAL, axiosInstance } from '../../configs';
import type Jasika from '../../interfaces/jasika';
import QueryJasikaBase from './_base';
import QueryBase from '../_base';
import type {
  DJ, DJamK, DK, DLN, DRJ, FJ, FK, FRJ, J, JamK, K, LN, RJ, TJ, TJamK, TK, TLN, TRJ,
} from './_type';
import QueryPermohonan from './permohonan';
import QueryJadwalKerja from './jadwalKerja';
import QuerySaldoCuti from './saldoCuti';

class QueryJasika {
  public jabatan: QueryJasikaBase<J, DJ, J['_id'], TJ, FJ>;

  public jadwalKerja: QueryJadwalKerja;

  public jamKerja: QueryBase<JamK, DJamK, JamK['_id'], TJamK>;

  public karyawan: QueryJasikaBase<K, DK, K['_id'], TK, FK>;

  public liburNasional: QueryBase<LN, DLN, LN['_id'], TLN>;

  public permohonan: QueryPermohonan;

  public revisiJadwal: QueryJasikaBase<RJ, DRJ, RJ['_id'], TRJ, FRJ>;

  public saldoCuti: QuerySaldoCuti;

  private backend: AxiosInstance;

  private url: string;

  constructor() {
    this.backend = axiosInstance(BACKEND_OPERASIONAL);
    this.url = '/';
    this.jabatan = new QueryJasikaBase(`${this.url}jabatan`, this.backend);
    this.jadwalKerja = new QueryJadwalKerja(this.backend);
    this.jamKerja = new QueryBase(`${this.url}jam-kerja`, this.backend);
    this.karyawan = new QueryJasikaBase(`${this.url}karyawan`, this.backend);
    this.liburNasional = new QueryBase(`${this.url}libur-nasional`, this.backend);
    this.permohonan = new QueryPermohonan(this.backend);
    this.revisiJadwal = new QueryJasikaBase(`${this.url}revisi-jadwal`, this.backend);
    this.saldoCuti = new QuerySaldoCuti(this.backend);
  }

  public index = async (): Promise<Jasika> => {
    const result = await this.backend.get<Jasika>(this.url);
    return result.data;
  };
}

export default QueryJasika;
