import React, { useReducer } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalDispatchContext, GlobalStateContext } from './states/context';
import reducer from './states/reducer';

const initialState = {
};

interface ProviderProps {
  children: React.ReactNode | React.ReactNode[];
}
function Provider(props: ProviderProps): JSX.Element {
  const { children } = props;

  const queryClient = new QueryClient();
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
          <BrowserRouter>
            {children}
          </BrowserRouter>
        </GlobalDispatchContext.Provider>
      </GlobalStateContext.Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default Provider;
