import React from 'react';
import type { RouteObject } from 'react-router-dom';
import RouteProfile from './profile';
import PageDashboard from '../pages/dashboard';
import TemplateDashboard from '../templates/dashboard';

function RouteDashboard(): RouteObject[] {
  return [
    {
      element: <TemplateDashboard />,
      children: [
        {
          index: true,
          element: <PageDashboard />,
        },
        {
          path: 'profile',
          children: RouteProfile(),
        },
      ],
    },
  ];
}

export default RouteDashboard;
