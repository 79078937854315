import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';
import {
  AlarmFill,
  CalendarDateFill,
  CalendarWeekFill,
  CardText, CreditCard, Diagram3Fill, KeyFill, PeopleFill, PersonFill,
} from 'react-bootstrap-icons';
import { GlobalStateContext } from '../states/context';
import findRule from '../utils/findRule';
import { CardMenu } from '../components/card';
import { FRONTEND_OPERASIONAL, FRONTEND_SISTEM } from '../configs';

function PageDashboard(): JSX.Element {
  const { hakAkses: { aturan } } = useContext(GlobalStateContext);

  const user = findRule(aturan, 'system:user');
  const hakAkses = findRule(aturan, 'system:hak-akses');
  const karyawan = findRule(aturan, 'jasika:karyawan');
  const liburNasional = findRule(aturan, 'jasika:libur-nasional');
  const jabatan = findRule(aturan, 'jasika:jabatan');
  const jadwalKerja = findRule(aturan, 'jasika:jadwal-kerja');
  const jamKerja = findRule(aturan, 'jasika:jam-kerja');
  const permohonan = findRule(aturan, 'jasika:permohonan');
  const saldoCuti = findRule(aturan, 'jasika:saldo-cuti');

  return (
    <Row className="justify-content-center">
      {(user) ? <CardMenu href={`${FRONTEND_SISTEM}/user`} icon={PersonFill} title="Data User" /> : null}
      {(hakAkses) ? <CardMenu href={`${FRONTEND_SISTEM}/hak-akses`} icon={KeyFill} title="Data Hak Akses" /> : null}
      {(karyawan) ? <CardMenu href={`${FRONTEND_OPERASIONAL}/karyawan`} icon={PeopleFill} title="Data Karyawan" /> : null}
      {(jabatan) ? <CardMenu href={`${FRONTEND_OPERASIONAL}/jabatan`} icon={Diagram3Fill} title="Data Jabatan" /> : null}
      {(jamKerja) ? <CardMenu href={`${FRONTEND_OPERASIONAL}/jam-kerja`} icon={AlarmFill} title="Data Jam Kerja" /> : null}
      {(permohonan) ? <CardMenu href={`${FRONTEND_OPERASIONAL}/permohonan`} icon={CardText} title="Formulir Absen" /> : null}
      {(saldoCuti) ? <CardMenu href={`${FRONTEND_OPERASIONAL}/saldo-cuti`} icon={CreditCard} title="Data Saldo Cuti" /> : null}
      {(jadwalKerja) ? <CardMenu href={`${FRONTEND_OPERASIONAL}/jadwal-kerja`} icon={CalendarWeekFill} title="Data Jadwal Kerja" /> : null}
      {(liburNasional) ? <CardMenu href={`${FRONTEND_OPERASIONAL}/libur-nasional`} icon={CalendarDateFill} title="Data Libur Nasional" /> : null}
    </Row>
  );
}

export default PageDashboard;
