import React, { useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import type { InputTimeProps } from './props';

function InputTime(props: InputTimeProps): JSX.Element {
  const {
    className,
    controlId,
    disabled,
    errMsg,
    handleOnChange,
    includeTimes,
    inputRef,
    isInvalid,
    label,
    max,
    min,
    name,
    onBlur,
    onChange,
    placeholder,
  } = props;

  const [selected, setSelected] = useState(null);

  return (
    <ReactDatePicker
      customInput={(
        <Form.Group className={className} controlId={controlId}>
          <FloatingLabel controlId={controlId} label={label}>
            <Form.Control
              ref={inputRef}
              disabled={disabled}
              isInvalid={isInvalid}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              type="text"
            />
            <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      )}
      includeTimes={includeTimes}
      maxTime={max}
      minTime={min}
      onChange={(date, event) => {
        setSelected(date);
        return handleOnChange(date, event);
      }}
      selected={selected}
      showTimeSelect
      showTimeSelectOnly
      timeCaption="Jam"
      timeFormat="HH:mm"
      timeIntervals={60}
    />
  );
}

InputTime.defaultProps = {
  handleOnChange: undefined,
  inputRef: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
};

export default InputTime;
