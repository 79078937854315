import React from 'react';
import { Button } from 'react-bootstrap';
import { PencilFill } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';
import type Props from './props';
import { SmallLoading } from '../loading';

function ChangePassword(props: Props): JSX.Element {
  const {
    children, href, isLoading, onClick,
  } = props;

  const buttonContent = isLoading ? <SmallLoading /> : <PencilFill />;

  const button = (
    <Button disabled={isLoading} onClick={onClick} size="sm" variant="success">
      {buttonContent}
      {' '}
      {children}
    </Button>
  );

  if (href) {
    return <LinkContainer to={href}>{button}</LinkContainer>;
  }

  return button;
}

ChangePassword.defaultProps = {
  href: undefined,
  isLoading: false,
  onClick: undefined,
};

export default ChangePassword;
